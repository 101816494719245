import Grid from "@mui/material/Unstable_Grid2";
import {
    AccordionDetails, AccordionSummary, Box,
    Card, Divider, Fab, Typography,
} from "@mui/material";
import CoolAudioPlayer from "../../../components/audioPlayer/coolAudioPlayer";
import CoolPlaylist from "../../../components/playlist/coolPlaylist";
import React, {lazy, Suspense, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import useResizableGlobeSize from "../../../hooks/globe/resizeGlobeMap";
import CoolAudioUtils from "../../../components/audioPlayer/coolAudioUtils";
import {useAudioUtilStore} from "../../../stores/audioUtilStore";
import {useUserStore} from "../../../stores/userStore";
import {Playlist} from "../../../types/Playlist";
import {NAccordion} from "../../../nasbai-design/NAccordion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {usePlaylistStore} from "../../../stores/playlistStore";
import {Delete} from "@mui/icons-material";
import NIconButtonDelete from "../../../nasbai-design/NButtonDelete";
import {useNavigate} from "react-router-dom";

const ExplorerDesktopPage = () => {
    const [expanded, setExpanded] = useState<string | false>('playlist0');
    const cardRef = useRef<HTMLDivElement | null>(null);
    const {isGlobeActive} = useAudioUtilStore();
    const utilsPropsLeft: AudioUtilProps = {utilSide: true}
    const utilsPropsRight: AudioUtilProps = {utilSide: false}
    const {globeSize} = useResizableGlobeSize({
        width: 1000,
        height: 1000,
    }, cardRef);
    const navigate = useNavigate();
    const user = useUserStore();
    const {getPlaylist, deletePlaylist, playlists} = usePlaylistStore();
    const globePlaylist = playlists.value.find((p: Playlist) => p.playlistId === 0);

    const switchPlaylist = useMemo(() => (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    }, []);

    useEffect(() => {
        if (user.isPremium()) {
            getPlaylist(user);
        }
    }, []);

    const CoolGlobe = useMemo(() => lazy(() => import('../../../components/explorer/coolGlobe')), []);
    const CoolCountrySelector = useMemo(() => lazy(() => import('../../../components/explorer/coolCountrySelector')), []);
    const CoolPlaylistUtils = useMemo(() => lazy(() => import('../../../components/playlist/coolPlaylistUtils')), []);

    const onDeleteClick = useCallback((playlistId: number) => {
        deletePlaylist(playlistId);
    }, [deletePlaylist]);


    return <Grid container direction="row" justifyContent="center">
        <Grid xs={12} md={6} sx={{mt: '70px'}} direction="column">
            <Grid>
                {isGlobeActive ?
                    <Card id="desktop-globe-card" ref={cardRef}>
                        <Suspense fallback={<div>Loading the Earth...</div>}>
                            <CoolGlobe width={globeSize.width} height={globeSize.height}/>
                        </Suspense>
                        <Fab variant="extended" color="secondary" sx={{
                            postion: 'relative',
                            bottom: '70px',
                            left: '20px'
                        }} onClick={() => navigate("/upload")}>
                            Upload Audio
                        </Fab>
                    </Card>
                    :
                    <Card id="desktop-globe-card" sx={{bgcolor: (t) => t.palette.background.paper}}>
                        <Suspense fallback={<div>Loading the Country Selector...</div>}>
                            <CoolCountrySelector/>
                        </Suspense>
                    </Card>
                }
            </Grid>
            <Grid>
                <Card id="desktop-play-card" sx={{bgcolor: (t) => t.palette.background.paper}}>
                    <CoolAudioUtils {...utilsPropsLeft} />
                    <CoolAudioPlayer/>
                    <CoolAudioUtils {...utilsPropsRight} />
                </Card>
            </Grid>
        </Grid>
        <Grid xs={12} md={5} sx={{mt: '70px'}} direction="column">
            {!user.isPremium() ?
                <>
                    <CoolPlaylist currentPlaylist={globePlaylist}/>
                    <Suspense fallback={<div>Loading the Playlist Utils...</div>}>
                        <CoolPlaylistUtils premiumBanner={true}/>
                    </Suspense>
                </>
                :
                <Box sx={{
                    overflowY: 'auto', maxHeight: '92vh',
                    '&::-webkit-scrollbar': {
                        '&-thumb': {
                            backgroundColor: theme => theme.scrollbar.thumb,
                            '&:hover': {
                                backgroundColor: theme => theme.scrollbar.hover,
                            }
                        },
                        '&-track': {
                            backgroundColor: theme => theme.scrollbar.track,
                        }
                    },
                }}>
                    <NAccordion expanded={expanded === 'playlist0'}
                                onChange={switchPlaylist('playlist0')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography color="text.primary">Globe Playlist</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CoolPlaylist currentPlaylist={globePlaylist}/>
                        </AccordionDetails>
                    </NAccordion>
                    <Divider sx={{ml: '15px', mb: '10px', bgcolor: t => t.background.default3}}/>
                    <Suspense fallback={<div>Loading the Playlist Utils...</div>}>
                        <CoolPlaylistUtils premiumBanner={false}/>
                    </Suspense>
                    {playlists.value.map((p) => (
                        p.playlistId !== 0 &&
                        <NAccordion key={p.playlistId} expanded={expanded === `playlist${p.playlistId + 1}`}
                                    onChange={switchPlaylist(`playlist${p.playlistId + 1}`)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography color="text.primary">{p.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CoolPlaylist currentPlaylist={p}/>
                            </AccordionDetails>
                            <NIconButtonDelete sx={{ml: '15px', mb: '0.5em'}}
                                               onClick={() => onDeleteClick(p.playlistId)}>
                                <Delete/>
                                Delete playlist
                            </NIconButtonDelete>
                        </NAccordion>
                    ))}
                </Box>
            }
        </Grid>
    </Grid>
}
export default ExplorerDesktopPage;