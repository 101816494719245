import Grid from "@mui/material/Unstable_Grid2";
import CoolPlaylist from "../../../components/playlist/coolPlaylist";
import {
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Card,
    Fab,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import CoolAudioPlayer from "../../../components/audioPlayer/coolAudioPlayer";
import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import {useSignal} from "@preact/signals-react";
import useFetchCountryList from "../../../hooks/data/fetchCountriesList";
import {useSelectedCountryStore} from "../../../stores/selectedCountryStore";
import {Country} from "../../../types/Country";
import NTextField from "../../../nasbai-design/NTextField";
import {usePlaylistStore} from "../../../stores/playlistStore";
import {Playlist} from "../../../types/Playlist";
import {NAccordion} from "../../../nasbai-design/NAccordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NIconButtonDelete from "../../../nasbai-design/NButtonDelete";
import {Add, Delete} from "@mui/icons-material";
import CoolPlaylistUtils from "../../../components/playlist/coolPlaylistUtils";
import {useUserStore} from "../../../stores/userStore";
import {useNavigate} from "react-router-dom";

const ExplorerMobilePage = () => {
    const {setCountry, setLoading, loadGlobePlaylist} = useSelectedCountryStore();
    const selectedCountry = useSignal('');
    const {countryList} = useFetchCountryList();
    const {playlists, deletePlaylist, getPlaylist} = usePlaylistStore();
    const globePlaylist = playlists.value.find((p: Playlist) => p.playlistId === 0);
    const [tabIndex, setTabIndex] = useState(0);
    const [expanded, setExpanded] = useState<string | false>('playlist0');
    const navigate = useNavigate();
    const user = useUserStore();

    useEffect(() => {
        if (user.isPremium()) {
            getPlaylist(user);
        }
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
        setTabIndex(newTabIndex);
    };

    const handleAutocomplete = (e: any, value: any) => {
        if (!value) return;
        selectedCountry.value = value.name;
        let country = countryList.filter(item => item.name === selectedCountry.value);
        if (country.length === 0) return;
        setCountry(country[0].id, country[0].name, country[0].admo_a3, country[0].iso_a2);
        setLoading(true);
        loadGlobePlaylist();
    }

    const handleIndependentCountryISO = (option: Country) => {
        if (option.iso_a2 === '-99') {
            return '';
        }
        return `https://flagcdn.com/w20/${option.iso_a2.toLowerCase()}.png`
    }

    const switchPlaylist = useMemo(() => (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    }, []);

    const onDeleteClick = useCallback((playlistId: number) => {
        deletePlaylist(playlistId);
    }, [deletePlaylist]);

    return (
        <Grid container direction="column" sx={{marginTop: '70px'}}>
            {!user.isPremium() ? <></> :
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    sx={{mb: '7px'}}
                >
                    <Tab label="World" sx={{color: t => t.palette.text.primary}}></Tab>
                    <Tab label="Custom Playlists" sx={{color: t => t.palette.text.primary}}></Tab>
                </Tabs>
            }
            <Box>
                {tabIndex === 0 && (
                    <>
                        <Grid xs={12} sx={{pl: '16px', pr: '16px'}}>
                            <Autocomplete
                                size="small"
                                sx={{width: '100%'}}
                                options={countryList}
                                autoHighlight
                                onChange={handleAutocomplete}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box key={key} component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...optionProps}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`${handleIndependentCountryISO(option)} 2x`}
                                                src={handleIndependentCountryISO(option)}
                                                alt=""
                                            />
                                            {option.name}
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => (
                                    <NTextField
                                        {...params}
                                        label="Choose a country"
                                        inputProps={{
                                            ...params.inputProps
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={4} direction="column">
                            <CoolPlaylist id="mobile-playlist" currentPlaylist={globePlaylist}/>
                        </Grid>
                    </>
                )}
                {tabIndex === 1 && (
                    <Box sx={{overflowY: 'auto', height: '120vh'}}>
                        <CoolPlaylistUtils premiumBanner={false}/>
                        {playlists.value.map((p) => (
                            p.playlistId !== 0 &&
                            <NAccordion sx={{margin: 0}}
                                        key={p.playlistId} expanded={expanded === `playlist${p.playlistId + 1}`}
                                        onChange={switchPlaylist(`playlist${p.playlistId + 1}`)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography color="text.primary">{p.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CoolPlaylist currentPlaylist={p}/>
                                </AccordionDetails>
                                <NIconButtonDelete sx={{ml: '15px', mb: '0.5em'}}
                                                   onClick={() => onDeleteClick(p.playlistId)}>
                                    <Delete/>
                                    Delete playlist
                                </NIconButtonDelete>
                            </NAccordion>
                        ))}
                    </Box>
                )}
            </Box>

            <Grid xs={12} className="mobile-grid">
                <Fab sx={{
                    position: 'relative',
                    bottom: '20px',
                    marginLeft: '80vw',
                }} onClick={() => navigate("/upload")} color="primary">
                    <Add/>
                </Fab>
                <Card id="mobile-play-card" sx={{bgcolor: (t) => t.palette.background.default}}>
                    <CoolAudioPlayer/>
                </Card>
            </Grid>
        </Grid>
    )
}

export default ExplorerMobilePage;