import React, {useEffect} from "react";
import {
    Box,
    Card,
    Grid, IconButton,
    Skeleton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@mui/material";
import NIconButton from "../../nasbai-design/NIconButton";
import {useSelectedCountryStore} from "../../stores/selectedCountryStore";
import Audio from "../../types/Audio";
import {useAudioPlayerStore} from "../../stores/audioPlayerStore";
import {formatDuration} from "../../hooks/data/formatDuration";
import {AccessTime, CheckCircle, Close, PlayArrow, Remove, Replay} from "@mui/icons-material";
import {Playlist} from "../../types/Playlist";
import {usePlaylistStore} from "../../stores/playlistStore";
import {useUserStore} from "../../stores/userStore";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

export const StyledCard = styled((props: any) => <Card {...props} />)(({theme}) => ({
    fontWeight: 'bold',
    fontFamily: 'inherit',
    background: theme.palette.background.paper,
    overflowY: 'auto',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    margin: '0 0px 0px 0px',
    borderRadius: '15px',
    [theme.breakpoints.up('xs')]: {
        height: '100vh',
    },
    [theme.breakpoints.up('md')]: {
        height: '67.5vh',
    },
}));

const AcceptIconButton = styled(IconButton)(({theme}) => ({
    color: theme.palette.text.primary,
    width: '25px',
    height: '25px',
    marginRight: 10,

    '&:hover': {
        backgroundColor: theme.palette.primary.main
    },
}));

const RejectIconButton = styled(IconButton)(({theme}) => ({
    color: theme.palette.text.primary,
    width: '25px',
    height: '25px',
    marginRight: 10,
    '&:hover': {
        backgroundColor: theme.info.deleteHover
    },
}));

const RemoveIconButton = styled(IconButton)(({theme}) => ({
    color: 'white',
    width: '25px',
    height: '25px',
    marginRight: 10,
    backgroundColor: theme.info.delete,
    '&:hover': {
        backgroundColor: theme.info.deleteHover
    },
}));

const CoolPlaylist = (props: { id?: string, currentPlaylist?: Playlist }) => {
    const selectedCountryStore = useSelectedCountryStore();
    const audioPlayerStore = useAudioPlayerStore();
    const {removeAudioFromPlaylist, acceptAudio, rejectAudio} = usePlaylistStore();
    const {roles} = useUserStore();

    const messageHandler = (event: any) => {
        switch (event.data) {
            case 'play':
                onPlayButtonClick(audioPlayerStore.currentAudio);
                break;
            case 'pause':
                audioPlayerStore.onClickHandler();
                break;
            // Add more cases if you have more controls
            default:
                break;
        }
    };

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', messageHandler);
        }

        return () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener('message', messageHandler);
            }
        }
    }, []);

    const onPlayButtonClick = (audio: Audio) => {
        if (audio.audioId != null && props.currentPlaylist !== undefined) {
            audioPlayerStore.loadCurrentState(audio, props.currentPlaylist);
            audioPlayerStore.initializeDashPlayer();

            if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    title: 'Play Notification',
                    body: `Playing ${audio.title}`,
                    icon: 'path_to_your_icon'
                });
            }
        }
    }

    const onRemoveAudioFromPlaylist = (audio: Audio) => {
        if (audio.audioId != null && props.currentPlaylist !== undefined) {
            removeAudioFromPlaylist(audio, props.currentPlaylist.playlistId);
        }
    }

    const onAcceptAudio = (audio: Audio) => {
        if (audio.audioId != null && props.currentPlaylist !== undefined) {
            acceptAudio(props.currentPlaylist.playlistId, audio);
        }
    }

    const onRejectAudio = (audio: Audio) => {
        if (audio.audioId != null && props.currentPlaylist !== undefined) {
            rejectAudio(props.currentPlaylist.playlistId, audio);
        }
    }

    const showReviewButtonsWhenModerator = (audio: Audio) => {
        if (roles.includes('ROLE_MODERATOR') && audio.isReviewed) {
            return <>
                <Tooltip title="Reviewed">
                    <CheckCircle sx={{ color: 'blue'}}/>
                </Tooltip>
            </>
        }
        if (roles.includes('ROLE_MODERATOR') && !audio.isReviewed) {
            return (
                <>
                    <Tooltip title="Accept Audio">
                        <AcceptIconButton
                            onClick={() => onAcceptAudio(audio)}>
                            <CheckCircleOutlineRoundedIcon/>
                        </AcceptIconButton>
                    </Tooltip>
                    <Tooltip title="Reject Audio">
                        <RejectIconButton
                            onClick={() => onRejectAudio(audio)}>
                            <Close/>
                        </RejectIconButton>
                    </Tooltip>
                </>
            );
        }
    }

    const hideUnreviewedAudioExceptForModerator = (audio: Audio) => {
        if (roles.includes('ROLE_MODERATOR')) {
            return true;
        }
        return audio.isReviewed;
    }

    function generateUUID(): string {
        let d = new Date().getTime(); // Timestamp
        let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16; // random number between 0 and 16
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    return <StyledCard id={props.id}>
        <Grid container
              direction="row"
              justifyContent="center">
            <Typography sx={{fontWeight: 'bold', mt: '15px', mb: '15px'}}
                        color="text.primary">{props.currentPlaylist?.description}</Typography>
        </Grid>

        <TableContainer component={Box}>
            <Table>
                <TableHead sx={{bgcolor: (t) => t.palette.background.paper}}>
                    <TableRow sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        '& > *': {fontFamily: 'inherit'}
                    }}>
                        <TableCell sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '63px',
                            minWidth: '63px'
                        }}>#</TableCell>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="right" sx={{justifyContent: 'flex-end', flex: 'auto'}}>
                            <AccessTime fontSize="small"/>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {props.currentPlaylist && props.currentPlaylist.audios.length > 0 ?
                    <TableBody>
                        {props.currentPlaylist.audios.map((audio) => (
                            hideUnreviewedAudioExceptForModerator(audio) &&
                            <TableRow
                                key={generateUUID()}
                                sx={{
                                    '& > td': {
                                        borderBottom: 'unset',
                                        fontFamily: 'inherit',
                                        '& > .remove-btn': {
                                            visibility: 'hidden',
                                        },
                                        '& > .hidden': {
                                            visibility: 'hidden',
                                        }
                                    },
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: audio.audioId === audioPlayerStore.currentAudio?.audioId ? (theme) => theme.info.selected : 'inherit',
                                    '&:hover': {
                                        backgroundColor: (theme) => theme.palette.background.default,
                                        '& > td': {
                                            '& > .remove-btn': {
                                                visibility: 'visible',
                                            },
                                        }
                                    }
                                }}
                            >
                                <TableCell align="left">
                                    <NIconButton sx={{margin: '0px'}} size="small"
                                                 onClick={() => onPlayButtonClick(audio)}>
                                        {audio.isPlaying ?
                                            <Replay sx={{color: 'black'}}/>
                                            :
                                            <PlayArrow sx={{color: 'black'}}/>
                                        }
                                    </NIconButton>
                                </TableCell>
                                <TableCell sx={{fontSize: '10pt'}}>
                                    {audio.title}
                                    {audio.username &&
                                        <Typography
                                            onClick={() => window.location.href = `/profile/${audio.username}`}
                                            variant="caption" sx={{
                                            color: 'text.primary',
                                            display: 'block',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }
                                        }}>
                                            {audio.username}
                                        </Typography>
                                    }
                                </TableCell>
                                <TableCell align="right"
                                           sx={{
                                               flex: 'auto',
                                               display: 'flex',
                                               flexDirection: 'row',
                                               alignItems: 'center',
                                               justifyContent: 'flex-end'
                                           }}>
                                    {showReviewButtonsWhenModerator(audio)}
                                    <Tooltip title="Remove from playlist">
                                        <RemoveIconButton
                                            onClick={() => onRemoveAudioFromPlaylist(audio)}
                                            className={props.currentPlaylist?.playlistId !== 0 ? 'remove-btn' : 'hidden'}>
                                            <Remove/>
                                        </RemoveIconButton>
                                    </Tooltip>
                                    {formatDuration(audio.duration)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    :
                    <TableBody>
                        {selectedCountryStore.isLoading ? <><TableRow>
                            <TableCell><Skeleton animation="wave" variant="circular" width={40}
                                                 height={40}/></TableCell>
                            <TableCell><Skeleton animation="wave" variant="rectangular"/></TableCell>
                            <TableCell><Skeleton animation="wave" variant="rectangular"/></TableCell>
                        </TableRow><TableRow>
                            <TableCell><Skeleton animation="wave" variant="circular" width={40}
                                                 height={40}/></TableCell>
                            <TableCell><Skeleton animation="wave" variant="rectangular"/></TableCell>
                            <TableCell><Skeleton animation="wave" variant="rectangular"/></TableCell>
                        </TableRow><TableRow>
                            <TableCell><Skeleton animation="wave" variant="circular" width={40}
                                                 height={40}/></TableCell>
                            <TableCell><Skeleton animation="wave" variant="rectangular"/></TableCell>
                            <TableCell><Skeleton animation="wave" variant="rectangular"/></TableCell>
                        </TableRow></> : <></>
                        }
                    </TableBody>
                }
            </Table>
        </TableContainer>
    </StyledCard>
}
export default CoolPlaylist;