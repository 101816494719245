import {create} from "zustand";
import {MutableRefObject} from "react";
import Audio from "../types/Audio";
import dashjs from "dashjs";
import {Playlist} from "../types/Playlist";
import {usePlaylistStore} from "./playlistStore";
import apiService from "../hooks/apiService";

/**
 * Audio Player State
 * -----------------
 *
 * Current Audio File and Playlist must be synchronized with the Playlist Store!
 *
 * currentAudio: The current Audio File <br>
 * currentPlaylist: The current Playlist
 *
 * duration: The Duration of the current Audio File
 * audioRef: The <audio> Reference
 * player: The Dash Player
 */
interface AudioPlayerState {
    currentAudio: Audio,
    currentPlaylist: Playlist,
    duration: number,
    audioRef: MutableRefObject<HTMLAudioElement | null>,
    player: dashjs.MediaPlayerClass,
}

/**
 * Audio Player Actions
 * --------------------
 *
 *  setAudioRef: Set the Audio Reference <br>
 *  initializeDashPlayer: Initialize the Dash Player And Plays the Audio <br>
 *  resetPlayer: Reset the Player <br>
 *  isPlayerInitialized: Check if the Player is Initialized <br>
 *  onClickHandler: Handle the Click Event <br>
 *  pause: Pause the Player <br>
 *  loadCurrentState: Load the Current State of the Audio and Playlist <br>
 *  next: Play the Next Audio File <br>
 *  previous: Play the Previous Audio File
 */
interface AudioPlayerAction {
    setAudioRef: (audioRef: MutableRefObject<HTMLAudioElement | null>) => void,
    initializeDashPlayer: () => void,
    resetPlayer: () => void,
    isPlayerInitialized: boolean,
    onClickHandler: () => void,
    pause: () => void,
    loadCurrentState: (audio: Audio, playlist: Playlist) => void,
    next: () => void,
    previous: () => void,
    playRandomAudioGlobal: () => Promise<void>,
}

type AudioPlayerStore = AudioPlayerAction & AudioPlayerState;

const API_URL = process.env.REACT_APP_API_URL;

const findIndexInPlaylist = (playlist: Playlist, audio: Audio) => {
    return playlist.audios.findIndex((a: Audio) => a.audioId === audio.audioId);
}

export const useAudioPlayerStore = create<AudioPlayerStore>((set, get) => ({
    duration: 0,
    audioRef: null as any,
    currentAudio: null as any,
    currentPlaylist: null as any,
    isPlayerInitialized: false,
    player: dashjs.MediaPlayer().create(),

    setAudioRef: (audioRef: MutableRefObject<HTMLAudioElement | null>) => {
        set({audioRef: audioRef});
    },

    initializeDashPlayer: () => {
        const {audioRef, currentAudio, currentPlaylist} = get();
        if (audioRef.current === null || currentAudio.filename === '') {
            return;
        }
        const {player: previousPlayer} = get();
        if (previousPlayer.isReady() && previousPlayer) {
            previousPlayer.pause();
            previousPlayer.reset();
        }

        const player = dashjs.MediaPlayer().create();
        player.initialize(audioRef.current, `${API_URL}/api/stream/dash/` + currentAudio.filename + ".mpd", false);
        player.play();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        syncCurrentPlaylistState(currentAudio, currentPlaylist, true);
        set({player: player, isPlayerInitialized: true, duration: currentAudio.duration});
    },

    resetPlayer: () => {
        const {player, currentPlaylist, currentAudio} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
        player.reset();
    },

    onClickHandler: () => {
        const {currentAudio, currentPlaylist, player, initializeDashPlayer} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        if (currentAudio === null || currentPlaylist === null) {
            return;
        }
        if (!player.isReady()) {
            initializeDashPlayer();
            return;
        }
        if (currentAudio.isPlaying) {
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            player.pause();
        } else {
            syncCurrentPlaylistState(currentAudio, currentPlaylist, true);
            player.play();
        }
        set({currentAudio: currentAudio, currentPlaylist: currentPlaylist});
    },

    loadCurrentState: (audio: Audio, playlist: Playlist) => {
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        syncCurrentPlaylistState(audio, playlist, true);
        set({currentAudio: audio, currentPlaylist: playlist});
    },

    pause: () => {
        const {player, currentAudio, currentPlaylist} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        player.pause();
        syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
        set({currentAudio: currentAudio, currentPlaylist: currentPlaylist});
    },

    next: () => {
        const {currentPlaylist, currentAudio} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        const index = findIndexInPlaylist(currentPlaylist, currentAudio);

        if (index + 1 < currentPlaylist.audios.length) {
            const nextAudio = currentPlaylist.audios[index + 1];
            nextAudio.isPlaying = true;
            currentAudio.isPlaying = false;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(nextAudio, currentPlaylist, true);
            set({currentAudio: nextAudio, currentPlaylist: currentPlaylist});
        } else {
            const firstAudio = currentPlaylist.audios[0];
            firstAudio.isPlaying = true;
            currentAudio.isPlaying = false;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(firstAudio, currentPlaylist, true);
            set({currentAudio: firstAudio, currentPlaylist: currentPlaylist});
        }
    },

    previous: () => {
        const {currentPlaylist, currentAudio} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        const index = findIndexInPlaylist(currentPlaylist, currentAudio);

        if (index - 1 >= 0) {
            const previousAudio = currentPlaylist.audios[index - 1];
            previousAudio.isPlaying = true;
            currentAudio.isPlaying = false;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(previousAudio, currentPlaylist, true);
            set({currentAudio: previousAudio, currentPlaylist: currentPlaylist});
        } else {
            const lastAudio = currentPlaylist.audios[currentPlaylist.audios.length - 1];
            lastAudio.isPlaying = true;
            currentAudio.isPlaying = false;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(lastAudio, currentPlaylist, true);
            set({currentAudio: lastAudio, currentPlaylist: currentPlaylist});
        }
    },

    playRandomAudioGlobal: async () => {
        const {currentAudio, currentPlaylist} = get();
        try {
            let response = await apiService.getRandomAudioGlobal();
            if (response.data === null) {
                return;
            }
            const {syncCurrentPlaylistState} = usePlaylistStore.getState();
            let newAudio: Audio = response.data;
            const index = findIndexInPlaylist(currentPlaylist, newAudio);
            const isNewAudio = index === -1;

            currentAudio.isPlaying = false;
            newAudio.isPlaying = true;

            if (isNewAudio) {
                currentPlaylist.audios.push(newAudio);
            } else {
                newAudio = currentPlaylist.audios[index];
            }

            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(newAudio, currentPlaylist, true);
            set({currentAudio: newAudio, currentPlaylist: currentPlaylist});
        } catch (error) {
            console.log(error);
        }
    }
}));