import {Box, IconButton, styled, Tooltip} from "@mui/material";
import {FavoriteBorder, Public, PublicOff} from "@mui/icons-material";
import NIconButton from "../../nasbai-design/NIconButton";
import {useAudioUtilStore} from "../../stores/audioUtilStore";
import Grid from "@mui/material/Unstable_Grid2";
import React, {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {usePlaylistStore} from "../../stores/playlistStore";
import {useAudioPlayerStore} from "../../stores/audioPlayerStore";
import {useUserStore} from "../../stores/userStore";
import {useSnackbarStore} from "../../stores/snackbarStore";
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';

const FavoriteIconButton = styled(IconButton)(({theme}) => ({
    marginTop: '8px',
    width: '30px',
    height: '30px',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.info.delete
        },
    }
}));

const ExploreIconButton = styled(IconButton)(({theme}) => ({
    marginTop: '8px',
    width: '30px',
    height: '30px',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
    },
    '&:hover': {
        backgroundColor: theme.info.hover
    },
}));

/**
 * Left or right side of the audio utils
 *
 * Left side is for the globe
 * Right side is for the favorite button
 *
 * @param props
 * @constructor
 */
const CoolAudioUtils = (props: AudioUtilProps) => {
    const {isGlobeActive, toggleGlobe, isDiscoveryAudioEnabled, toggleDiscoveryAudio} = useAudioUtilStore();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {playlists, addAudioToPlaylist} = usePlaylistStore();
    const {currentAudio} = useAudioPlayerStore();
    const {showSnackbar} = useSnackbarStore();
    const {isPremium} = useUserStore();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (playlists.value.length === 1) {
            showSnackbar("You don't have any playlists", 'error');
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateGlobeSizeAndToggle = () => {
        toggleGlobe();
    }

    const onDiscoveryAudioClick = () => {
        toggleDiscoveryAudio();
    }

    function onFavoriteAudioClick(playlistId: number) {
        return () => {
            handleClose();
            if (currentAudio === null) {
                return;
            }
            addAudioToPlaylist(currentAudio, playlistId);
        }
    }

    if (props.utilSide) {
        return <Box display="flex" justifyContent="flex-start" alignItems="center">
            <NIconButton onClick={updateGlobeSizeAndToggle}>
                {isGlobeActive ?
                    <Tooltip title={"Disable Globe"}>
                        <PublicOff sx={{color: 'black'}}/>
                    </Tooltip>
                    :
                    <Tooltip title={"Show Globe"}>
                        <Public sx={{color: 'black'}}/>
                    </Tooltip>
                }
            </NIconButton>
        </Box>;
    } else {
        return <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Menu
                id="favorite-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {playlists.value.map((playlist) => (
                    playlist.playlistId !== 0 &&
                    <MenuItem key={playlist.playlistId}
                              onClick={onFavoriteAudioClick(playlist.playlistId)}>{playlist.name}</MenuItem>
                ))}
            </Menu>
            <Grid container display="flex">
                <Grid xs={6} sm={6} md={12} lg={12} xl={6} sx={{
                    pr: {
                        xs: '0px',
                        sm: '5px'
                    }
                }}>
                    {isPremium() ?
                        <Tooltip title={"Add to Playlist"}>
                            <FavoriteIconButton onClick={handleClick}>
                                <FavoriteBorder sx={{color: 'black'}}/>
                            </FavoriteIconButton>
                        </Tooltip> : <></>
                    }
                </Grid>
                <Grid>
                    <ExploreIconButton onClick={onDiscoveryAudioClick}>
                        {isDiscoveryAudioEnabled ?
                            <Tooltip title={"Disable Discover Mode"}>
                                <ExploreIcon sx={{color: 'black'}}/>
                            </Tooltip> :
                            <Tooltip title={"Enable Discover Mode"}>
                                <ExploreOffIcon sx={{color: 'black'}}/>
                            </Tooltip>
                        }
                    </ExploreIconButton>
                </Grid>

            </Grid>
        </Box>;
    }
}

export default CoolAudioUtils;