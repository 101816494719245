import Grid from "@mui/material/Unstable_Grid2";
import NButtonLight from "../../nasbai-design/NButtonLight";
import React from "react";
import {usePlaylistStore} from "../../stores/playlistStore";
import {useUserStore} from "../../stores/userStore";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {Button, Tooltip} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import NTextField from "../../nasbai-design/NTextField";

const CoolPlaylistUtils = (props: { premiumBanner: boolean }) => {
    const {createPlaylist} = usePlaylistStore();
    const user = useUserStore();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const PremiumBanner = () => {
        if (props.premiumBanner) {
            return (
                <Tooltip title={"You need to be a premium user to create a playlist"}>
                    <span>
                        <NButtonLight disabled={props.premiumBanner}>
                            Create New Playlist
                        </NButtonLight>
                    </span>
                </Tooltip>
            )
        } else {
            return (
                <NButtonLight onClick={handleClickOpen} disabled={props.premiumBanner}>
                    Create New Playlist
                </NButtonLight>
            )
        }
    }

    return (
        <Grid container justifyContent="center" sx={{mb: '1em', mt: '1em'}}>
            <PremiumBanner/>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const name = formJson.name;
                        const description = formJson.description;
                        createPlaylist(name, description, user);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Create a new Playlist</DialogTitle>
                <DialogContent>
                    <DialogContentText color="text.primary">
                        Please enter the name of your new playlist
                    </DialogContentText>
                    <NTextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Playlist Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <DialogContentText color="text.primary" sx={{mt: '10px'}}>
                        Please enter a description for your playlist
                    </DialogContentText>
                    <NTextField
                        autoFocus
                        margin="dense"
                        id="description"
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default CoolPlaylistUtils;