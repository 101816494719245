import {create} from "zustand";

interface AudioUtilState {
    isGlobeActive: boolean;
    isDiscoveryAudioEnabled: boolean;
}

interface AudioUtilAction {
    toggleGlobe: () => void;
    toggleDiscoveryAudio: () => void;
}

type AudioUtilStore = AudioUtilAction & AudioUtilState;

export const useAudioUtilStore = create<AudioUtilStore>((set, get) => ({
    isGlobeActive: true,
    isDiscoveryAudioEnabled: false,
    toggleGlobe: () => {
        const {isGlobeActive} = get();
        set({isGlobeActive: !isGlobeActive});
    },
    toggleDiscoveryAudio: () => {
        const {isDiscoveryAudioEnabled} = get();
        set({isDiscoveryAudioEnabled: !isDiscoveryAudioEnabled});
    }
}));